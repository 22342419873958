.custom-header {
  padding-top: 20px;
  position: relative;
}

.focal-point-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #3a3a3a;
  margin: 0;
}

.focus-image-container {
  height: 400px;
  position: relative;
}

.focus-picker-img {
  width: 100%;
  object-fit: contain;
}

.focus-header {
  font-size: 1rem;
  font-weight: 500;
  color: #2887af;
}

.focus-image-container-desktop-wrapper {
  box-shadow: 0px 4px 27px rgba(62, 101, 136, 0.15),
    0px 2px 10px rgba(62, 101, 136, 0.15);
  max-width: 500px;
  width: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
}

.focus-image-container-mobile-wrapper {
  box-shadow: 0px 4px 27px rgba(62, 101, 136, 0.15),
    0px 2px 10px rgba(62, 101, 136, 0.15);
  padding-bottom: 15px;
  position: absolute !important;
  max-width: 111px;
  aspect-ratio: 16/9;
  bottom: 0px !important;
  right: 0 !important;
  background-color: #fff;
}

.focus-image-container-desktop {
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
}

.focus-image-container-mobile {
  width: 111px;
  height: 163px;
}

.focus-image-container-mobile-wrapper .group-info p {
  font-size: 7px !important;
  padding-bottom: 0px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.focus-image-container-mobile-wrapper .group-info {
  padding: 0px !important;
}

.group-info {
  width: 100%;
  aspect-ratio: 21/3;
}

.focus-image-container-mobile-wrapper .icon-wrapper {
  padding: 0px !important;
}

.focus-image-container-mobile-wrapper .icon-wrapper {
  width: 6px !important;
  height: 6px !important;
}

.focus-image-container-mobile-wrapper .icon-wrapper img {
  width: 6px;
  height: 6px;
}

.focused-image {
  transition: top 0.25s ease-in-out, left 0.25s ease-in-out;
}

.modal-80w {
  max-width: 85% !important;
}

.group-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #3a3a3a;
  padding: 0px;
  margin: 10px 7px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-wrapper {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  margin-right: 0px;
  background: #fff;
  box-shadow: 0.336022px 1.34409px 1.34409px rgba(62, 101, 136, 0.15),
    0.672045px 0.672045px 3.36022px rgba(62, 101, 136, 0.15);
}

.cover-image-controls {
  display: flex;
}

.cover-image-controls img {
  width: 10px;
  height: 10px;
}

.conver-edit-dropdown .dropdown button {
  position: relative;
  bottom: 60px;
  outline: none;
  box-shadow: none;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #2887af;
  background-image: linear-gradient(to right, #2887af, #3e6588);
  color: var(--bs-white) !important;
  font-weight: 600;
}

.conver-edit-dropdown .dropdown .dropdown-menu.show {
  border: none;
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  border-radius: 3px;
  padding: none;
}

.conver-edit-dropdown .dropdown .dropdown-menu.show .dropdown-item {
  border-bottom: none;
}

.focal-point-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #3e6588;
}

.preview-wrapper > .row > div > .focus-image-container {
  height: auto;
}

.sort-dropdown {
  font-size: 13px;
  width: fit-content;
  min-width: 289px;
  height: 40px;
  font-weight: 500;
  color: #2887af !important;
  background: none !important;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  text-align: left;
  padding-left: 1.25rem;
}

.sort-dropdown:active,
.sort-dropdown:hover,
.sort-dropdown:focus {
  background: none !important;
  color: #2887af !important;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08) !important;
}

.sort-dropdown + div {
  border: 0;
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
}

.sort-dropdown + div a.active {
  background-color: #dbeff8 !important;
}

.sort-dropdown + div a {
  color: #00000066 !important;
  width: 100% !important;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500 !important;
  height: 40px !important;
}

.sort-dropdown img,
.sort-dropdown + div a.dropdown-item img {
  height: 24px !important;
  width: 21px !important;
  margin-right: 12px !important;
}

.group-name-div {
  max-width: 625px !important;
}

.group-name-div .cover-edit {
  margin-top: 12px;
  height: 50px;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .md-jcb {
    justify-content: space-between;
  }

  .modal-80w {
    max-width: auto;
    margin: auto;
  }

  .focal-point-title {
    font-size: 18px;
  }

  .preview-wrapper .focus-image-container {
    max-height: 300px;
    aspect-ratio: 16 / 9;
  }

  .preview-wrapper .focus-image-container .focus-picker-img {
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (min-width: 1200px) {
  .w-xl-400 {
    max-width: 400px !important;
  }
}
