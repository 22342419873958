.parent-container {
  width: 100%;
  margin: auto;
  background-color: #f3f3f3;
}

.column1 {
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.column1 p {
  font-size: min(calc(2vw + 10px), 38px);
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-left: 32px;
  color: white;
}

.column2 {
  border-radius: 15px;
  background: var(--bs-white);
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    2px 2px 10px rgba(62, 101, 136, 0.15);
}

.column-2-child img {
  width: 80%;
  height: 98%;
}

.portfolio {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  padding: 1.5rem;
}

.portfolio::after {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, transparent 65%, black);
}

.portfolio p {
  font-size: min(2vw + 10px, 2rem);
  font-weight: 600;
  color: white;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 2;
}

.portfolio-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  bottom: 2rem;
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-image: radial-gradient(rgba(0, 0, 0, 0.5), transparent);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-icon img {
  height: 16px;
}

.portfolio-container {
  aspect-ratio: 19 / 9;
  margin-top: 20px;
}

.header-container {
  width: 100%;
  background: var(--bs-white);
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    2px 2px 10px rgba(62, 101, 136, 0.15);
  padding: 10px 0;
}

.backicon {
  font-size: 23px;
  font-weight: 600;
}

.media-icon {
  width: 49px;
}

.section-divide {
  display: none;
  height: 12px;
  width: 100%;
  background-color: #f3f3f3;
}

.portfolio-cover {
  aspect-ratio: 33 / 10;
}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;
}

.share-text {
  font-size: 18px !important;
  color: #3e6588;
  font-weight: 600;
  margin: 0;
  align-self: center;
}

.share img {
  height: 24px;
  width: 24px;
}

.services-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.services-div img {
  height: 80px;
  width: 80px;
  padding: 4px;
}

.services-price tr,
.services-price td {
  height: unset;
}

.services-price td {
  padding-bottom: 4px;
  padding-right: 12px;
  font-size: 15px;
}

@media (max-width: 1440px) {
  .column1 {
    border-radius: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-cover {
    aspect-ratio: 33 / 11;
  }
}

@media screen and (max-width: 992px) {
  .portfolio-cover {
    aspect-ratio: 33 / 12;
  }

  .portfolio-container {
    aspect-ratio: 1 / 0.6;
  }
}

@media screen and (max-width: 768px) {
  .portfolio {
    padding: 1rem;
  }

  .portfolio-cover {
    aspect-ratio: 33 / 15;
  }

  .column1 {
    border-radius: 0px;
  }

  .column2 {
    box-shadow: none;
    border-radius: 0;
  }

  .column2:first-of-type {
    min-height: unset;
    height: fit-content !important;
  }

  .column2:nth-child(2) {
    margin-top: 12px;
  }

  .section-divide {
    display: block;
  }

  .share-text {
    font-size: 13px !important;
  }

  .services-div img {
    height: calc(60px + 1.5vw);
    width: calc(60px + 1.5vw);
  }
}

@media screen and (max-width: 576px) {
  .portfolio-cover {
    aspect-ratio: 33 / 19;
    width: 100%;
  }

  .portfolio-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .portfolio-cover {
    aspect-ratio: 33 / 16;
    width: 100%;
  }
}

.backbutton {
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 100;
  height: 52px;
  width: 52px;
  border-radius: 50%;
}

.portfolio-header {
  box-shadow: 1px 4px 4px rgba(62, 101, 136, 0.15),
    -1px -1px 10px rgba(62, 101, 136, 0.08);
  background: #ffffff;
}

.portfolio-header nav img {
  height: 44px;
}

.tab-accordion h4 {
  display: none;
}

.tab-accordion p {
  font-size: 15px !important;
}

.tab-accordion {
  height: 76px;
  overflow: hidden;
}

.tab-accordion.active {
  overflow: auto;
  height: unset;
}

.accordion-btn {
  transform: rotate(-90deg);
  height: 18px;
  transition: transform 0.3s ease-in-out;
}

.accordion-btn.active {
  transform: rotate(90deg);
}
