.pglanding h2 {
  font-size: 30px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.cover-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1.5rem;
  margin: 0;
  width: 100%;
}

.nav li {
  padding: 1.5rem 0;
}

.nav li a {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}

.logo {
  max-height: 100px;
  max-width: 190px;
  object-fit: fill;
  margin: 0 1rem;
}

.welcome,
.credits {
  text-align: center;
  color: #fff;
}

.welcome {
  margin: 0 auto;
  padding: 0 2rem;
}

.welcome h1 {
  font-size: 40px;
  font-weight: bold;
}

.welcome h2 {
  font-size: 24px;
  font-weight: bold;
}

.welcome p {
  font-size: 20px;
  font-weight: 500;
}

.centered {
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  padding: 0;
}

.credits {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
}

.credits span {
  font-size: 15px;
  font-weight: 500;
}

.credits img {
  max-height: 20px;
  max-width: 100px;
}

.credits-dark {
  color: #121212;
}

.action-btn {
  background-color: transparent;
  border: 1px solid #fff !important;
  color: #fff;
  height: 50px !important;
  padding: 0.5rem 1rem;
  font-size: 15px;
  font-weight: 600;
  margin: auto;
  gap: 8px;
  transition: gap 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.action-btn:hover {
  gap: 16px;
  scale: 1.025;
}

.about {
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-width: min(100%, 800px);
}

.about p {
  text-align: center;
  margin: 0 auto 1.5rem;
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.socials img {
  height: 50px !important;
  width: 50px !important;
}

.book {
  padding: 10rem 1rem;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.book h2 {
  color: #fff;
  margin: 0;
  font-size: 40px;
}

.services-container,
.portfolio {
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  gap: 2rem;
}

.portfolio {
  gap: 0;
}

.services-container h2 {
  text-align: left;
}

.services-container > div {
  flex: 1;
}

.services-container > div:last-of-type {
  max-height: 670px;
}

.services-container > div:last-of-type > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services {
  height: fit-content;
}

.services :global(.services-div) {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 12px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  flex: 1 1 254px;
}

.join span {
  font-size: 13px;
}

.join img {
  height: 16px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .services-container > div:last-of-type {
    max-height: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .nav {
    display: none;
  }

  .logo {
    margin: 0;
  }

  :global(#ocnv) {
    background-color: rgba(red, 0, 0, 0.75);
  }

  .services-container > div:last-of-type {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .cover-container,
  .book {
    background-attachment: scroll;
  }

  .logo {
    max-height: 80px;
    max-width: 165px;
  }
}

@media screen and (max-width: 768px) {
  .pglanding h2 {
    font-size: 24px;
    text-align: center !important;
  }

  .welcome h1 {
    font-size: 32px;
  }

  .welcome h2 {
    font-size: 20px;
  }

  .welcome p {
    font-size: 16px;
  }

  .about {
    padding: 2.5rem 1rem 2.5rem;
  }

  .about p {
    font-size: 15px;
  }

  .socials img {
    height: 34px !important;
    width: 34px !important;
  }

  .book {
    padding: 5rem 1rem;
  }

  /* react slick doesn't shows up when this is not set */
  .services-container,
  .portfolio {
    display: block;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .services h2 {
    margin-bottom: 14px;
  }

  .services :global(.slick-track) {
    padding: 10px 0 !important;
  }

  .join span {
    font-size: 10px;
  }

  .join img {
    height: 12px;
  }
}

@media screen and (max-width: 576px) {
  .logo {
    max-height: 60px;
    max-width: 150px;
  }
}
